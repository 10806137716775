<template>
    <div></div>
</template>

<script>
export default {
    methods: {
        auth(scope) {
            scope = ['snsapi_base', 'snsapi_userinfo'][scope];
            let app_id = this.$store.state.wx_config.app_id;
            let url = this.config.frontend_url + this.$route.fullPath.replace('auth', 'login');
            /**
             * 进入授权页，参数path表示授权完成后的页面url：    /auth?path=/pagepath?q1=a1&a2=a2
             * 前往微信进行授权，携带参数redirect_uri：        redirect_uri=/login?path=/pagepath?q1=a1&q2=a2
             * 授权完成，跳转回login页面，根据path参数在跳转至：/pagepath?q1=a1&q2=a2
             */
            let auth_url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${app_id}&redirect_uri=${encodeURIComponent(url)}&response_type=code&scope=${scope}&state=${scope}#wechat_redirect`;
            location.replace(auth_url);
        },
    },
    created() {
        this.$toast({ type: 'loading', message: '登录中...', duration: 0 });
        let f = () => {
            if (this.$store.state.wx_config && this.$store.state.wx_config.app_id) {
                this.auth(this.$route.query.type ? 1 : 0);
            } else {
                setTimeout(f, 5);
            }
        };
        if (this.$route.query.code) {
        } else {
            setTimeout(f, 20);
        }
    },
};
</script>

<style></style>
